import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BADGE_GROUPS } from '../utils/badge-groups.ts';
import './BadgeGroupsComponent.css';
import { BadgeComponent } from '../components/BadgeComponent.tsx';
import { badge_submission_url, BadgeModes } from '../containers/MainButtonsContainer.tsx';
import { Badge } from '../hooks/useBadges.ts';
import { getAutoUpdatedMap } from '../hooks/getAutoUpdatedMap.ts';

const getInitialState = (badgeIDs: string[] | null) => {
  const map = BADGE_GROUPS.reduce((acc, group) => {
    group.badgesIDs.forEach((id) => {
      acc.set(id, badgeIDs?.includes(id) || false);
    });
    return acc;
  }, new Map<string, boolean>());

  return map;
};

const getExtraBadgeDescription = (
  badge: Badge,
  badgesMap: Map<string, Badge>,
  enabledBadgesMap: Map<string, boolean>
): string[] | null => {
  if (enabledBadgesMap.get(badge.id)) {
    return null;
  }

  if (badge.banSelfClaim && badge.autoClaimRules) {
    return [`🔒 No Self-claim.`];
  }

  if (badge.restrictedRules) {
    const missingBadges = badge.restrictedRules.ids
      .filter((id) => !enabledBadgesMap.get(id))
      .map((id) => badgesMap.get(id)?.name);
    if (missingBadges.length === 0) {
      return null;
    } else if (missingBadges.length === 1) {
      return [`🔑 Unlocks with:\n${missingBadges[0]}`];
    } else if (missingBadges.length === 5) {
      return [`🔑 Unlocks with:\n+1 Around the Offices`];
    }
  }

  return null;
};

interface GetBadgePropertiesProps {
  badge: Badge;
  mode: BadgeModes;
  enabledBadgesMap: Map<string, boolean>;
  profileBadgeIDs: string[] | null;
  badgeCreditsSubmissions: string[];
  badgeDesignSubmissions: string[];
}

const getBadgeProperties = ({
  badge,
  mode,
  enabledBadgesMap,
  badgeCreditsSubmissions,
  badgeDesignSubmissions,
  profileBadgeIDs,
}: GetBadgePropertiesProps) => {
  const submittedBadgeIDs = [...badgeCreditsSubmissions, ...badgeDesignSubmissions];

  const lowOpacity =
    mode === 'submitted'
      ? !submittedBadgeIDs?.includes(badge.id)
      : profileBadgeIDs != null && !enabledBadgesMap.get(badge.id) && mode !== 'badgegallery';

  const designIncluded =
    mode === 'submitted' &&
    (badgeDesignSubmissions.includes(badge.id) || (badge.design == null && badgeCreditsSubmissions.includes(badge.id)));

  const ideaIncluded = mode === 'submitted' && badgeCreditsSubmissions.includes(badge.id);

  const display = mode === 'submitted' ? submittedBadgeIDs?.includes(badge.id) : true;

  const badgeIsDesignedOrSubmittedByThisPerson =
    badgeCreditsSubmissions.includes(badge.id) || badgeDesignSubmissions.includes(badge.id);

  const blackAndWhite =
    mode === 'badgegallery'
      ? false
      : mode === 'submitted'
      ? !badgeIsDesignedOrSubmittedByThisPerson
      : !enabledBadgesMap.get(badge.id);

  const disableInteraction =
    (mode === 'submitted' && !badgeIsDesignedOrSubmittedByThisPerson) ||
    (mode === 'editingprofile' &&
      ((badge.banSelfClaim && !enabledBadgesMap.get(badge.id)) ||
        (badge.restrictedRules &&
          badge.restrictedRules.ids.filter((id) => enabledBadgesMap.get(id)).length < badge.restrictedRules.count)));

  const showName = !disableInteraction && (mode !== 'submitted' || submittedBadgeIDs?.includes(badge.id));

  const cursorNotAllowed =
    disableInteraction ||
    (mode === 'editingprofile' &&
      badge.autoClaimRules &&
      badge.autoClaimRules.ids.some((id) => enabledBadgesMap.get(id)));

  return {
    blackAndWhite,
    display,
    lowOpacity,
    disableInteraction,
    showName,
    designIncluded,
    ideaIncluded,
    cursorNotAllowed,
  };
};

export const BadgeGroupsComponent = ({
  profileBadgeIDs,
  badgesMap,
  mode,
  onClickBadge,
  setSaveDisabled,
  setEnabledBadgesForClipboard,
  showPercentages = true,
  badgeCreditsSubmissions,
  badgeDesignSubmissions,
  email,
}: {
  profileBadgeIDs: string[] | null;
  badgesMap: Map<string, Badge>;
  mode: BadgeModes;
  onClickBadge: (badge: Badge) => void;
  setSaveDisabled: (disabled: boolean) => void;
  setEnabledBadgesForClipboard: (enabledBadges: string[]) => void;
  showPercentages: boolean;
  badgeCreditsSubmissions: string[];
  badgeDesignSubmissions: string[];
  email: string;
}) => {
  const [enabledBadgesMap, setEnabledBadgesMap] = useState(new Map<string, boolean>());
  const submittedBadgeIDs = useMemo(() => {
    return [...badgeCreditsSubmissions, ...badgeDesignSubmissions];
  }, [badgeCreditsSubmissions, badgeDesignSubmissions]);

  // set enabled badges to badgeIDS from profileBadgeIDs
  useEffect(() => {
    // when email updates, update enabledBadgesMap
    setEnabledBadgesMap(getInitialState(profileBadgeIDs));
  }, [email, profileBadgeIDs]);

  useEffect(() => {
    setEnabledBadgesForClipboard([...enabledBadgesMap.entries()].filter(([_, enabled]) => enabled).map(([id]) => id));
  }, [enabledBadgesMap, setEnabledBadgesForClipboard]);

  const handleOnClickBadge = useCallback(
    (badge: Badge) => {
      if (mode !== 'editingprofile') {
        return onClickBadge?.(badge);
      }
      setSaveDisabled?.(false);
      // toggle badge enabled state
      let newMap = new Map(enabledBadgesMap);
      newMap.set(badge.id, !enabledBadgesMap.get(badge.id));

      newMap = getAutoUpdatedMap(newMap, badgesMap, {
        submittedBadgeIDs: submittedBadgeIDs,
        email,
      });

      setEnabledBadgesMap(newMap);
    },
    [badgesMap, email, enabledBadgesMap, mode, onClickBadge, setSaveDisabled, submittedBadgeIDs]
  );

  const newlyAddedBadges = [...enabledBadgesMap.entries()]
    .filter(([id, enabled]) => enabled && !profileBadgeIDs?.includes(id))
    .map(([id]) => badgesMap.get(id)!);
  const removedBadges = [...enabledBadgesMap.entries()]
    .filter(([id, enabled]) => !enabled && profileBadgeIDs?.includes(id))
    .map(([id]) => badgesMap.get(id)!);

  // Only the badges that have a key in badgesMap but are not in any badge group
  const badgesThatWerentInAnyBadgeGroup = useMemo(() => {
    const allBadgeIDs = [...badgesMap.keys()];
    const allGroupBadgeIDs = BADGE_GROUPS.reduce((acc, group) => {
      return acc.concat(group.badgesIDs);
    }, [] as string[]);

    return allBadgeIDs.filter((id) => !allGroupBadgeIDs.includes(id));
  }, [badgesMap]);

  if (badgesThatWerentInAnyBadgeGroup.length > 0) {
    console.error(
      `Badges that are not in any badge group: ${badgesThatWerentInAnyBadgeGroup.join(
        ', '
      )}. Please check the badge groups.`
    );
  }

  // Render component with a special className to denote if badge is enabled or not
  // Clicking the badge will toggle the enabled state
  return (
    <div className="collected-badges-container" style={{ marginBottom: '2rem' }}>
      <div className="collected-badges-inner-container" style={{ gap: '1.2rem', marginBottom: '1rem' }}>
        {mode === 'editingprofile' && profileBadgeIDs?.length
          ? (removedBadges.length > 0 || newlyAddedBadges.length > 0) && (
              <div
                style={{ width: '100%', gap: '0.5rem', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                {removedBadges.length ? (
                  <p style={{ textAlign: 'center' }} className="talos-red">
                    - {removedBadges.map((badge) => badge.name).join(', ')} <br />
                  </p>
                ) : null}
                {newlyAddedBadges.length ? (
                  <p style={{ textAlign: 'center' }} className="talos-green">
                    + {newlyAddedBadges.map((badge) => badge.name).join(', ')} <br />
                  </p>
                ) : null}
              </div>
            )
          : null}
        {BADGE_GROUPS.filter((a) =>
          mode === 'submitted' ? a.badgesIDs.some((id) => submittedBadgeIDs?.includes(id)) : true
        ).map((group) => {
          const percentage = !showPercentages
            ? 100
            : parseInt(
                (
                  (group.badgesIDs.filter((id) => enabledBadgesMap.get(id)).length / group.badgesIDs.length) *
                  100
                ).toFixed(2)
              );
          return (
            <GradientDiv
              color1={group.colors[0]}
              color2={group.colors[1]}
              percentage={percentage}
              mode={mode}
              key={group.name}
            >
              <h3 style={{ marginBottom: '1rem' }}>
                {group.name} {showPercentages ? <i>({percentage}%)</i> : null}
              </h3>
              <div
                style={{
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  display: 'flex',
                  gap: '1rem',
                }}
              >
                {group.badgesIDs
                  .map((id) => badgesMap.get(id))
                  .filter((badge) => badge != null)
                  .sort((a, b) => {
                    return (
                      b.participantIDsWhoOwnThisBadge.length / b.rarityScore -
                      a.participantIDsWhoOwnThisBadge.length / a.rarityScore
                    );
                  })
                  .map((badge) => {
                    const {
                      blackAndWhite,
                      display,
                      lowOpacity,
                      disableInteraction,
                      showName,
                      designIncluded,
                      ideaIncluded,
                      cursorNotAllowed,
                    } = getBadgeProperties({
                      badge,
                      mode,
                      enabledBadgesMap,
                      badgeCreditsSubmissions,
                      badgeDesignSubmissions,
                      profileBadgeIDs,
                    });

                    return (
                      <BadgeComponent
                        badge={badge}
                        extraDescription={
                          mode === 'editingprofile' && !enabledBadgesMap.get(badge.id)
                            ? getExtraBadgeDescription(badge, badgesMap, enabledBadgesMap)
                            : null
                        }
                        onClick={handleOnClickBadge}
                        blackAndWhite={blackAndWhite}
                        display={display}
                        smaller={true}
                        showFloatingNameShadow={false}
                        lowOpacity={lowOpacity}
                        key={badge.id}
                        showNameHeight={true}
                        enableInteraction={true}
                        showName={showName}
                        hiddenName={disableInteraction && mode === 'editingprofile'}
                        designIncluded={designIncluded}
                        ideaIncluded={ideaIncluded}
                        initiallyHidden={mode === 'editingprofile' && !enabledBadgesMap.get(badge.id)}
                        isEditing={mode === 'editingprofile'}
                        cursorNotAllowed={cursorNotAllowed}
                      />
                    );
                  })}
              </div>
            </GradientDiv>
          );
        })}
      </div>
      {mode === 'editingprofile' && <div className="talos-green">Scroll to top to save changes.</div>}
      {mode === 'submitted' && submittedBadgeIDs?.length === 0 && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.4rem', marginTop: '0.4rem' }}>
          <p style={{ marginTop: '0.6rem' }}>None submitted yet.</p>
          <button className="main-button" onClick={() => window.open(badge_submission_url, '_blank')}>
            <p>
              New Badge Submission <span className="talos-green">◆</span>
            </p>
          </button>
          <div className="h-stack" style={{ justifyContent: 'flex-start' }}>
            {/* Incentives for new badge submittion, Badger and Badge Baron */}
            <BadgeComponent badge={badgesMap.get('65ry')!} onClick={onClickBadge} smaller={true} />
            <BadgeComponent badge={badgesMap.get('uwpa')!} onClick={onClickBadge} smaller={true} />
          </div>
        </div>
      )}
    </div>
  );
};

const GradientDiv = ({
  color1,
  color2,
  percentage,
  children,
  mode,
}: {
  color1: string;
  color2: string;
  percentage: number;
  children: any;
  mode?: BadgeModes;
}) => {
  const containerStyle = {
    position: 'relative' as 'relative',
    maxWidth: '43rem',
    overflow: 'hidden', // Ensures only the clipped part of the gradient is visible
    border:
      mode === 'submitted'
        ? '0.15rem dashed gold'
        : `0.15rem dashed ${
            percentage < 100 ? (mode === 'editingprofile' ? 'rgb(96, 199, 174)' : 'black') : 'transparent'
          }`,
    marginLeft: '0',
    marginRight: '0',
  };

  const gradientStyle = {
    background: `linear-gradient(to right, ${color1}, ${color2})`,
    width: '100%',
    height: '100%',
    position: 'absolute' as 'absolute',
    left: 0,
    top: 0,
    opacity: percentage === 0 ? 0 : 0.35 + (percentage / 100) * 0.25,
    clipPath: `polygon(0 0, ${percentage}% 0, ${percentage === 0 ? 0 : percentage + 20}% 100%, 0 100%)`,
  };

  return (
    <div className="badge-group" style={containerStyle}>
      <div style={{ zIndex: 2, height: '100%', alignContent: 'center' }}>{children}</div>
      <div className="badge-group-inner" style={gradientStyle}></div>
    </div>
  );
};
