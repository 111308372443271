import { BADGE_GROUPS } from './badge-groups.ts';

export interface BadgeDescription {
  name: string;
  description: string;
  rarityScore: 1 | 2 | 4 | 8;
  credits: string;
  design?: string;
  // If any of these ids are missing, the badge cannot be claimed yet.
  // You need to have at least count of ids to claim this badge.
  restrictedRules?: { count: number; ids: string[] };
  // If any of these ids are present, this badge gets automatically claimed.
  // You automatically claim this badge if you have at least count of ids.
  autoClaimRules?: { count: number; ids: string[] };
  // ban self claim
  banSelfClaim?: boolean;
}

const traveledToSingleOfficeRule = { count: 1, ids: ['fk3y', 'ziyg', '56jo', 'zprr', 'qpwb'] };
const finishedOneYearRule = { count: 1, ids: ['tpbo'] };

export const BADGE_DESCRIPTIONS = new Map<string, BadgeDescription>([
  [
    'eaze',
    {
      name: 'New Pixel',
      description: 'Completed onboarding at Talos.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: {
        count: 1,
        // New Pixel is automatically claimed if any badge is claimed.
        ids: BADGE_GROUPS.map((group) => group.badgesIDs)
          .flat()
          .filter((id) => id !== 'eaze'),
      },
    },
  ],
  [
    'x7tl',
    {
      name: 'SRT Distributooor',
      description: 'Distributed 100 SRT in a single month.',
      rarityScore: 1,
      credits: 'andrew.theodosiou@talostrading.com',
      autoClaimRules: { count: 1, ids: ['pq4t', 'cxaa'] },
    },
  ],
  [
    'fk3y',
    {
      name: 'Full English Breakfast',
      description: 'Checked in at the London office.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['t61t'] },
    },
  ],
  [
    'ziyg',
    {
      name: 'Everything Bagel',
      description: 'Checked in at the New York office.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['jw0k'] },
    },
  ],
  [
    '56jo',
    {
      name: 'Orchid Odyssey',
      description: 'Checked in at the Singapore office.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      design: 'katarina.lauritano@talostrading.com',
    },
  ],
  [
    'zprr',
    {
      name: 'Halloumi Heaven',
      description: 'Checked in at the Cyprus office.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'qpwb',
    {
      name: 'Port of Gothenburg',
      description: 'Checked in at the Gothenburg office.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'tpbo',
    {
      name: 'Foundational Forest',
      description: 'Accumulated 1+ year at Talos.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['dr56', '86xv'] },
    },
  ],
  [
    'jt0d',
    {
      name: 'Champion',
      description: 'Scored a win at any Game Night event.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      design: 'constantine@talostrading.com',
    },
  ],
  [
    'dr56',
    {
      name: 'Hackathon Participant',
      description: 'Participated in the 2024 AI: Unleashed Hackathon.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      restrictedRules: finishedOneYearRule,
      autoClaimRules: { count: 1, ids: ['62ex'] },
    },
  ],
  [
    'krih',
    {
      name: 'Crypto Conqueror',
      description: 'Completed a blockchain or cryptocurrency course/certification.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      design: 'katarina.lauritano@talostrading.com',
    },
  ],
  [
    'mxv2',
    {
      name: 'Butter Fingers',
      description: 'Lost your office key card and requested a replacement.',
      rarityScore: 2,
      credits: 'andrew.theodosiou@talostrading.com',
      restrictedRules: traveledToSingleOfficeRule,
    },
  ],
  [
    'gm0p',
    {
      name: 'Soho Social',
      description: 'Attended a London evening social.',
      rarityScore: 1,
      credits: 'alexandra.gardmo@talostrading.com',
    },
  ],
  [
    'rw0q',
    {
      name: 'Social Butterfly',
      description: 'Attended 5+ company social events.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'rtdk',
    {
      name: 'Office Olympian',
      description: 'Participated in a company health and wellness event or initiative.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      design: 'katarina.lauritano@talostrading.com',
    },
  ],
  [
    '52nh',
    {
      name: 'Pet Parade Participant',
      description: 'Shared adorable pet photos in #off-pets.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'nmyu',
    {
      name: 'The Frank',
      description: 'Had audio/video technical difficulties during a company-wide presentation.',
      rarityScore: 1,
      credits: 'anton.deshchenko@talostrading.com',
      design: 'katarina.lauritano@talostrading.com',
    },
  ],
  [
    'n0w4',
    {
      name: 'Interview Initiate',
      description: 'Conducted 1 interview for a potential Talos candidate.',
      rarityScore: 1,
      credits: 'kel.ezekwe@talostrading.com',
      autoClaimRules: { count: 1, ids: ['1a0x'] },
    },
  ],
  [
    'iqzd',
    {
      name: 'Red Stapler',
      description: 'Watched Office Space (1999).',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'gu53',
    {
      name: 'PBGY',
      description: 'Bragged a perfect NYT Connections win in #off-nytconnections.',
      rarityScore: 2,
      credits: 'anton.deshchenko@talostrading.com',
    },
  ],
  [
    'hl4y',
    {
      name: 'Lasagna',
      description: 'Participated in a 10+ person Skribbl session.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    't61t',
    {
      name: 'Fika Feast',
      description: 'Attended Fika in the London office.',
      rarityScore: 1,
      credits: 'alexandra.gardmo@talostrading.com',
      restrictedRules: { count: 1, ids: ['fk3y'] },
    },
  ],
  [
    'wg99',
    {
      name: 'Beef Wellington Bandit',
      description: 'Successfully baked a Beef Wellington.',
      rarityScore: 2,
      credits: 'kyle.wiltshire@talostrading.com',
    },
  ],
  [
    'ifu1',
    {
      name: 'Guinness Master',
      description: 'Enjoyed a Guinness with teammates.',
      rarityScore: 1,
      credits: 'andrew.theodosiou@talostrading.com',
      autoClaimRules: { count: 1, ids: ['c9da'] },
    },
  ],
  [
    'ao3c',
    {
      name: 'Katz Calling',
      description: 'Had a pastrami sandwich from Katz Delicatessen in NYC.',
      rarityScore: 1,
      credits: 'anton.deshchenko@talostrading.com',
    },
  ],
  [
    '7y79',
    {
      name: 'Founder Homage Trail',
      description: 'Visited Williamsburg in Brooklyn.',
      rarityScore: 1,
      credits: 'anton.deshchenko@talostrading.com',
    },
  ],
  [
    'nnm0',
    {
      name: 'Event Enthusiast',
      description: 'Successfully organized 1 major company event.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['om4e'] },
    },
  ],
  [
    'y0az',
    {
      name: 'Tunesmith',
      description: 'Played the New York office piano.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['ziyg'] },
    },
  ],
  [
    '1tgz',
    {
      name: 'Retreat Ranger',
      description: 'Participated in a Talos retreat.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['iuvn'] },
    },
  ],
  [
    'iuvn',
    {
      name: 'Hidden Talent',
      description: 'Performed at a Talos Talent Show.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['1tgz'] },
    },
  ],
  [
    'hoeg',
    {
      name: 'Unscripted Hero',
      description: 'Gave a surprise demo at the Thursday all-hands.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    '9x0m',
    {
      name: 'Portrait Pioneer',
      description: 'Snapped a photo with Anton or Ethan at a Talos event.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'xray',
    {
      name: "Anton's Impostor",
      description: 'Eliminated Anton in Among Us.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['86xv'] },
    },
  ],
  [
    '7ays',
    {
      name: 'Cheer Leader',
      description: 'Was first to celebrate an achievement in #welcome-and-celebrations.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'htci',
    {
      name: 'Buzz Builder',
      description: 'Received 5+ reacts on a post in #random.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'jjqu',
    {
      name: 'Stretch Squad',
      description: 'Joined a weekly stretch session with Allison.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'snp0',
    {
      name: 'All for One',
      description: 'Received a single 100 SRT award from one person (excluding event prizes).',
      rarityScore: 2,
      credits: 'pedro.luan@talostrading.com',
    },
  ],
  [
    'cxaa',
    {
      name: 'One for All',
      description: 'Awarded your entire monthly 100 SRT to one person.',
      rarityScore: 1,
      credits: 'pedro.luan@talostrading.com',
    },
  ],
  [
    'nwqw',
    {
      name: 'Out of Office',
      description: 'Worked remotely for over half the working days in a single year.',
      rarityScore: 1,
      credits: 'rebecca.lumsdon@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'fyzv',
    {
      name: 'Volunteer',
      description: 'Attended a Talos volunteer event.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
      design: 'katarina.lauritano@talostrading.com',
    },
  ],
  [
    'tnlq',
    {
      name: 'Holiday Hero',
      description: 'Shared a photo of your travels with a Talos backpack in a shared channel.',
      rarityScore: 1,
      credits: 'rebecca.lumsdon@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'a4jq',
    {
      name: 'Katz Fishhook Survivor',
      description: 'Survived a Talos phishing attempt from “Anton Katz.”',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'sy7t',
    {
      name: 'Jira Initiate',
      description: 'Created 50+ Jira tickets.',
      rarityScore: 1,
      credits: 'kate.duggan@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'dz9q',
    {
      name: 'Honorary Viking',
      description: 'Braved a chilly swim in Swedish waters.',
      rarityScore: 2,
      credits: 'daniel@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'zqtm',
    {
      name: 'Helan Går!',
      description: 'Took part in the traditional Swedish “Helan Går!” festivities.',
      rarityScore: 1,
      credits: 'daniel@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'lvrp',
    {
      name: 'Good Darts!',
      description: 'Won a set of “Around the World” on the London darts board.',
      rarityScore: 1,
      credits: 'andrew.theodosiou@talostrading.com',
      restrictedRules: { count: 1, ids: ['fk3y'] },
    },
  ],
  [
    'pq4t',
    {
      name: 'In Debt!',
      description: 'Spent all your SRT on the first day of the monthly reset.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'l1ys',
    {
      name: 'Rolling 6s',
      description: 'Played backgammon with a coworker in any Talos office.',
      rarityScore: 1,
      credits: 'andrew.theodosiou@talostrading.com',
      restrictedRules: traveledToSingleOfficeRule,
    },
  ],
  [
    'jw0k',
    {
      name: 'Shoot Well!',
      description: 'Won a “Wheel” on the New York office darts board.',
      rarityScore: 1,
      credits: 'daniel.lezama@talostrading.com',
      design: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['ziyg'] },
    },
  ],
  [
    'b4uv',
    {
      name: 'Swag Hunter',
      description: 'Redeemed merch from the SRT store.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'rt3p',
    {
      name: 'Poker Shark',
      description: 'Attended an SRT poker evening.',
      rarityScore: 2,
      credits: 'andrew.theodosiou@talostrading.com',
    },
  ],
  [
    'p12z',
    {
      name: 'Gitcraft Wizard',
      description: 'Reached 100+ commits across all Talos products.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['uv79'] },
    },
  ],
  [
    '21yr',
    {
      name: 'Explorer',
      description: 'Checked in at 2+ Talos offices.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 2, ids: ['fk3y', 'ziyg', '56jo', 'zprr', 'qpwb'] },
      banSelfClaim: true,
    },
  ],
  [
    'ixey',
    {
      name: 'Yeah Buddy',
      description: 'Acted as a buddy to a new joiner.',
      rarityScore: 2,
      credits: 'andrew.theodosiou@talostrading.com',
    },
  ],
  [
    '86xv',
    {
      name: 'Emerald Shield',
      description: 'Accumulated 2+ years at Talos.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['gu8b', 'nogh'] },
      restrictedRules: finishedOneYearRule,
    },
  ],
  [
    'q6ss',
    {
      name: 'Junior Jedi',
      description: 'Mentored new hires or junior staff for 1+ year.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      restrictedRules: finishedOneYearRule,
    },
  ],
  [
    'n26f',
    {
      name: 'Presenter Prodigy',
      description: 'Presented at a Talos Tech or Crypto Bi-Weekly session.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    '1a0x',
    {
      name: 'Interview Instructor',
      description: 'Conducted 10+ interviews for a potential Talos candidate.',
      rarityScore: 2,
      credits: 'kel.ezekwe@talostrading.com',
      autoClaimRules: { count: 1, ids: ['slg4'] },
      restrictedRules: { count: 1, ids: ['n0w4'] },
    },
  ],
  [
    'edgc',
    {
      name: 'Mirror Image',
      description: 'Got your own Slack emoji created by someone else.',
      rarityScore: 2,
      credits: 'anton.deshchenko@talostrading.com',
    },
  ],
  [
    'gcr2',
    {
      name: 'Ragster Riches',
      description: 'Collected 4+ Talos-related T-shirts.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'jdoi',
    {
      name: 'On-Call Oracle',
      description: 'Successfully served on-call support duties.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    '0lkm',
    {
      name: 'Referral Rookie',
      description: 'Made 1 successful candidate referral to Talos.',
      rarityScore: 4,
      credits: 'kyle.wiltshire@talostrading.com',
      autoClaimRules: { count: 1, ids: ['bxbq'] },
    },
  ],
  [
    'c3gi',
    {
      name: 'Radical Rower',
      description: 'Attended a Rowbots class.',
      rarityScore: 2,
      credits: 'kyle.wiltshire@talostrading.com',
    },
  ],
  [
    'om4e',
    {
      name: 'Event Maestro',
      description: 'Successfully organized 3+ major company events.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['nnm0'] },
      autoClaimRules: { count: 1, ids: ['h588'] },
    },
  ],
  [
    '6sj5',
    {
      name: 'Deal Conqueror',
      description: 'Closed a deal with a new client.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      design: 'constantine@talostrading.com',
      autoClaimRules: { count: 1, ids: ['deal'] },
    },
  ],
  [
    'mgju',
    {
      name: 'Talent Talisman',
      description: 'Recruited 10+ candidates who completed their first year.',
      rarityScore: 4,
      credits: 'kyle.wiltshire@talostrading.com',
      design: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['zuoo'] },
      restrictedRules: finishedOneYearRule,
    },
  ],
  [
    '3mjs',
    {
      name: 'Crypto Chef',
      description: 'Baked something for Fika.',
      rarityScore: 2,
      credits: 'alexandra.gardmo@talostrading.com',
    },
  ],
  [
    'c9da',
    {
      name: 'Split The G',
      description: 'Discovered the Talos secret society.',
      rarityScore: 2,
      credits: 'anton.deshchenko@talostrading.com',
      restrictedRules: { count: 1, ids: ['ifu1'] },
    },
  ],
  [
    '62ex',
    {
      name: 'Hackathon Winner',
      description: 'Won the 2024 AI: Unleashed Hackathon.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      banSelfClaim: true,
      autoClaimRules: { count: 1, ids: [] },
    },
  ],
  [
    'gu8b',
    {
      name: 'Wisdom Guardian',
      description: 'Accumulated 4+ years at Talos.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['86xv'] },
      autoClaimRules: { count: 1, ids: ['nogh'] },
    },
  ],
  [
    'tk16',
    {
      name: 'Culture Catalyst',
      description: 'Actively served in the Talos Culture Club.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['eaze'] },
    },
  ],
  [
    '65ry',
    {
      name: 'Badger',
      description: 'Submitted a badge to Talos Badges.',
      rarityScore: 2,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['uwpa'] },
      banSelfClaim: true,
    },
  ],
  [
    '1ze3',
    {
      name: 'Knowledge Sharer',
      description: 'Donated a book to a Talos bookshelf.',
      rarityScore: 2,
      credits: 'pedro.luan@talostrading.com',
    },
  ],
  [
    '26vr',
    {
      name: 'Office Junkie',
      description: 'Checked in at any Talos office 300+ times.',
      rarityScore: 2,
      credits: 'rahil.ramchandani@talostrading.com',
      design: 'ralfi@talostrading.com',
      restrictedRules: finishedOneYearRule,
    },
  ],
  [
    'r2v8',
    {
      name: 'Phish Guard',
      description: 'Never clicked a phishing link sent by the Talos Security Team.',
      rarityScore: 1,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    '1mew',
    {
      name: 'Jira Enthusiast',
      description: 'Created 250+ Jira tickets.',
      rarityScore: 2,
      credits: 'kate.duggan@talostrading.com',
      design: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['4xjt'] },
      restrictedRules: { count: 1, ids: ['sy7t'] },
    },
  ],
  [
    '99lz',
    {
      name: 'Fantasy NFL Champion',
      description: 'Won a Talos Fantasy NFL league.',
      rarityScore: 4,
      credits: 'kate.duggan@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    't4lx',
    {
      name: 'All Hands Timekeeper',
      description: 'Presented the timestamp at a Talos all-hands meeting.',
      rarityScore: 2,
      credits: 'teresa.garcia@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'ao8z',
    {
      name: 'The Road Runner',
      description: 'Represented Talos at the JPM Corporate Challenge or Bloomberg Square Mile Relay.',
      rarityScore: 2,
      credits: 'kat@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'g4xq',
    {
      name: 'Proud Mom at Work',
      description: 'Brought your mom to any Talos office.',
      rarityScore: 2,
      credits: 'bharat@talostrading.com',
      restrictedRules: traveledToSingleOfficeRule,
    },
  ],
  [
    '8nll',
    {
      name: 'Battle Hardened!',
      description: 'Partied a bit too hard at a Talos event.',
      rarityScore: 2,
      credits: 'thierry.hue@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    '1m01',
    {
      name: 'Wall St Rides',
      description: 'Took part in the Wall Street Rides FAR event in NYC.',
      rarityScore: 2,
      credits: 'ant@talostrading.com',
      design: 'ralfi@talostrading.com',
    },
  ],
  [
    'h588',
    {
      name: 'Event Legend',
      description: 'Masterfully organized 5+ major company events.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['om4e'] },
    },
  ],
  [
    'hpai',
    {
      name: 'Jet Boots',
      description: 'Checked in at 4+ Talos offices.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 4, ids: ['fk3y', 'ziyg', '56jo', 'zprr', 'qpwb'] },
      banSelfClaim: true,
    },
  ],
  [
    'slg4',
    {
      name: 'Interview Icon',
      description: 'Conducted 100+ interviews for a potential Talos candidate.',
      rarityScore: 4,
      credits: 'kel.ezekwe@talostrading.com',
      restrictedRules: { count: 1, ids: ['1a0x'] },
    },
  ],
  [
    'zuoo',
    {
      name: 'Talent Magnate',
      description: 'Recruited 50+ candidates who completed their first year.',
      rarityScore: 8,
      credits: 'kyle.wiltshire@talostrading.com',
      restrictedRules: { count: 1, ids: ['mgju'] },
    },
  ],
  [
    'uv79',
    {
      name: 'Gitcraft Archmage',
      description: 'Reached 2000+ commits across all Talos products.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['p12z'] },
    },
  ],
  [
    'bxbq',
    {
      name: 'Referral Royalty',
      description: 'Made 5+ successful candidate referrals to Talos.',
      rarityScore: 8,
      credits: 'kyle.wiltshire@talostrading.com',
      restrictedRules: { count: 1, ids: ['0lkm'] },
    },
  ],
  [
    'mh96',
    {
      name: 'Tech Trailblazer',
      description: 'Published a tech article in a recognized forum or journal.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'ayyc',
    {
      name: 'Support Star',
      description: 'Resolved 100+ customer or internal support tickets.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    'a1y2',
    {
      name: 'Support Beacon',
      description: 'Resolved 1000+ customer or internal support tickets.',
      rarityScore: 8,
      credits: 'andrew.matuza@talostrading.com',
      design: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['ayyc'] },
    },
  ],
  [
    '0gfr',
    {
      name: 'Galactic Speaker',
      description: 'Presented a keynote at a major industry conference on behalf of Talos.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
    },
  ],
  [
    '20nu',
    {
      name: 'Pixel Prestige',
      description: 'Current champion for highest lifetime earned SRT.',
      rarityScore: 8,
      credits: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: [] },
      banSelfClaim: true,
    },
  ],
  [
    'nogh',
    {
      name: 'Timekeeper of Truth',
      description: 'Accumulated 6+ years at Talos.',
      rarityScore: 8,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['gu8b'] },
    },
  ],
  [
    '4xjt',
    {
      name: 'Jira Overlord',
      description: 'Created 1000+ Jira tickets. Everything okay?',
      rarityScore: 4,
      credits: 'kate.duggan@talostrading.com',
      design: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['1mew'] },
    },
  ],
  [
    'uwpa',
    {
      name: 'Badge Baron',
      description: 'Submitted 10+ badges to Talos Badges.',
      rarityScore: 4,
      credits: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['65ry'] },
      autoClaimRules: { count: 1, ids: [] },
      banSelfClaim: true,
    },
  ],
  [
    'ibdm',
    {
      name: "Talos Olympics Winner 24'",
      description: 'Helped lead the winning team at the 2024 Talos Olympics.',
      rarityScore: 4,
      credits: 'kat@talostrading.com',
      design: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['1tgz'] },
    },
  ],
  [
    'p5m0',
    {
      name: 'Talos Offsite Football Champion',
      description: 'Won the Talos Champions League at an offsite event.',
      rarityScore: 4,
      credits: 'kel.ezekwe@talostrading.com',
      design: 'alvaro.dapollonio@talostrading.com',
      restrictedRules: { count: 1, ids: ['1tgz'] },
    },
  ],
  [
    'j4l9',
    {
      name: 'Fantasy Football Gold Champion',
      description: 'Claimed 1st place in the Talos Fantasy Premier League.',
      rarityScore: 4,
      credits: 'kel.ezekwe@talostrading.com',
    },
  ],
  [
    'bpzs',
    {
      name: 'Talos Badges QA Specialist',
      description: 'Crashed the Talos Badges website.',
      rarityScore: 2,
      credits: 'anton.deshchenko@talostrading.com',
      design: 'ralfi@talostrading.com',
      banSelfClaim: true,
      autoClaimRules: { count: 1, ids: [] },
    },
  ],
  [
    'herm',
    {
      name: 'Traveller I',
      description: 'Travelled to at least 3 continents in one year.',
      rarityScore: 1,
      credits: 'rahul.relekar@talostrading.com',
      design: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['qpnd'] },
    },
  ],
  [
    'qpnd',
    {
      name: 'Traveller II',
      description: 'Travelled to at least 4 continents in one year.',
      rarityScore: 2,
      credits: 'rahul.relekar@talostrading.com',
      design: 'ralfi@talostrading.com',
      autoClaimRules: { count: 1, ids: ['20c8'] },
      restrictedRules: { count: 1, ids: ['herm'] },
    },
  ],
  [
    '20c8',
    {
      name: 'Traveller III',
      description: 'Travelled to at least 5 continents in one year.',
      rarityScore: 4,
      credits: 'rahul.relekar@talostrading.com',
      design: 'ralfi@talostrading.com',
      restrictedRules: { count: 1, ids: ['qpnd'] },
    },
  ],
  [
    'olof',
    {
      name: 'Olof Approved',
      description: 'The most coveted of badges. Your idea or PR was approved by Olof.',
      rarityScore: 2,
      credits: 'katarina.lauritano@talostrading.com',
    },
  ],
  [
    'g0x1',
    {
      name: 'Talos London Gym Club',
      description: 'Attended a workout session in Everyone Active with a co-worker.',
      rarityScore: 2,
      credits: 'thomas.kennedy@talostrading.com',
      restrictedRules: { count: 1, ids: ['fk3y'] },
    },
  ],
  [
    '2j0v',
    {
      name: 'Flaky Test Martyr',
      description: 'Spent over a week diagnosing and fixing a flaky test.',
      rarityScore: 2,
      credits: 'rob@talostrading.com',
    },
  ],
  [
    'b2x1',
    {
      name: 'Flaky Test Samaritan',
      description: 'Fixed more than 5 flaky tests introduced by others.',
      rarityScore: 1,
      credits: 'rob@talostrading.com',
    },
  ],
  [
    'mqpm',
    {
      name: 'Flaky Test Acolyte',
      description: 'Fixed more than 10 different causes of flaky tests from your own tests.',
      rarityScore: 1,
      credits: 'rob@talostrading.com',
    },
  ],
  [
    'deal',
    {
      name: 'Deal Maker',
      description: 'Closed a deal with a new client while being in a different region.',
      rarityScore: 4,
      credits: 'frank.vanzegveld@talostrading.com',
      restrictedRules: traveledToSingleOfficeRule,
    },
  ],
]);
