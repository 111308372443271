import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './MainButtonsContainer.css';
import './CollectedBadgesContainer.css';
import Select from 'react-select';
import { formatDistanceToNow } from 'date-fns';
import { JourneysContainer } from './JourneysContainer.tsx';
import { BadgeGroupsComponent } from '../components/BadgeGroupsComponent.tsx';
import { JOURNEYS } from '../utils/journeys.ts';
import { Profile } from '../hooks/useProfiles.ts';
import { Badge } from '../hooks/useBadges.ts';

const new_paste_url = 'https://forms.gle/tNDAiWw2z3aVF7s87';
export const badge_submission_url = 'https://forms.gle/Dd9m5FEWtSZhn2fY7';
const awardEmojis = ['🥇', '🥈', '🥉'] as const;
const talosBadgesLogoURL = `${process.env.PUBLIC_URL}/images/talos-badges-logo.png`;

export type BadgeModes =
  | 'splash'
  | 'collected'
  | 'submitted'
  | 'journeys'
  | 'editingprofile'
  | 'badgegallery'
  | 'people';

export const createThisProfileButton = ({
  createDisabled,
  displayName,
  enabledBadges,
}: {
  createDisabled: boolean;
  displayName: string;
  enabledBadges: string[];
}) => {
  return (
    <button
      className={`main-button ${createDisabled ? 'disabled faded-out-text' : ''} `}
      onClick={() => {
        if (createDisabled) return;
        navigator.clipboard.writeText(getClipboardText(displayName, enabledBadges));
        // navigate to new_paste_url, replace this tab.
        window.open(new_paste_url, '_blank');
      }}
      disabled={createDisabled}
    >
      <p>
        Create this Profile <span className="talos-green">◆</span>
      </p>
    </button>
  );
};

const useSyncedRef = <T,>(value: T) => {
  const valueRef = useRef(value);
  useEffect(() => {
    valueRef.current = value;
  }, [value]);
  return valueRef;
};

const getClipboardText = (displayName: string, enabledBadges: string[]) => {
  return `${displayName}, ${enabledBadges.join('')}`;
};

interface MainButtonsContainerProps {
  profilesMap: Map<string, Profile>;
  badgesMap: Map<string, Badge>;
  selectedProfileID: string | null;
  setSelectedProfileID: (profileID: string | null) => void;
  selectedBadge: Badge | null;
  setSelectedBadge: (badge: Badge | null) => void;

  setGhostProfileID: (profileID: string | null) => void;
  setImagesLoaded: (fullyLoaded: boolean) => void;
  mode: BadgeModes;
  setMode: (mode: BadgeModes) => void;
}

export const MainButtonsContainer = ({
  profilesMap,
  badgesMap,
  selectedProfileID,
  setSelectedProfileID,
  setSelectedBadge,
  setGhostProfileID,
  setImagesLoaded,
  mode,
  setMode,
}: MainButtonsContainerProps) => {
  const [buttonPressed, setButtonPressed] = useState(false);
  const buttonPressedRef = useSyncedRef(buttonPressed);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [sortBy, setSortBy] = useState('score');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [enabledBadges, setEnabledBadges] = useState<string[]>([]);
  const [displayName, setDisplayName] = useState('');
  const [leaveEditModeKey, setLeaveEditModeKey] = useState(0);

  console.log('in here now', badgesMap);

  const selectedProfile = useMemo(() => {
    return selectedProfileID ? profilesMap.get(selectedProfileID) ?? null : null;
  }, [selectedProfileID, profilesMap]);
  const selectedProfileName = useMemo(() => {
    return selectedProfile?.email.split('@')[0].split('.')[0];
  }, [selectedProfile]);

  useEffect(() => {
    setSaveDisabled(true);
  }, [selectedProfileID, setMode]);

  useEffect(() => {
    if (selectedProfile != null) {
      setDisplayName(selectedProfile.name);
    } else {
      setDisplayName('');
    }
  }, [selectedProfile]);

  const createDisabled = useMemo(() => {
    return saveDisabled || !displayName || displayName.includes(',');
  }, [saveDisabled, displayName]);

  const updateDisabled = useMemo(() => {
    return (saveDisabled && displayName === selectedProfile?.name) || displayName.includes(',');
  }, [saveDisabled, displayName, selectedProfile]);

  const options = useMemo(() => {
    const options = Array.from(profilesMap.values()).map((profile, index) => ({
      value: profile.email,
      label: `${index <= 2 ? awardEmojis[index] : `#${index + 1}`} ${profile.name}`,
      score: profile.sortScore,
      lastUpdated: profile.lastUpdated,
      topEmoji: profile.journeyEmojis.at(0) ?? '',
    }));
    if (sortBy === 'score') {
      return options.sort((a, b) => b.score - a.score);
    } else if (sortBy === 'last updated') {
      return options.sort((a, b) => b.lastUpdated.getTime() - a.lastUpdated.getTime());
    }

    return [];
  }, [profilesMap, sortBy]);

  const handleKeyDown = useCallback(
    (e) => {
      if (menuIsOpen) {
        return;
      }
      if (mode === 'editingprofile') {
        return;
      }
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
        e.preventDefault();
        e.stopPropagation();
      }
      if ((e.key === 'ArrowUp' || e.key === 'ArrowLeft') && buttonPressedRef.current === false) {
        setButtonPressed(true);
        if (selectedProfileID == null) {
          setMode('collected');
          return setSelectedProfileID(options[options.length - 1].value);
        }
        const foundID = options[options.findIndex((option) => option.value === selectedProfileID) - 1]?.value ?? null;
        setSelectedProfileID(foundID);
        if (!foundID) {
          setMode('splash');
        }
      }
      if ((e.key === 'ArrowDown' || e.key === 'ArrowRight') && buttonPressedRef.current === false) {
        setButtonPressed(true);
        if (selectedProfileID == null) {
          setMode('collected');
          return setSelectedProfileID(options[0].value);
        }
        const foundID = options[options.findIndex((option) => option.value === selectedProfileID) + 1]?.value ?? null;
        setSelectedProfileID(foundID);
        if (!foundID) {
          setMode('splash');
        }
      }
    },
    [menuIsOpen, mode, setMode, buttonPressedRef, selectedProfileID, setSelectedProfileID, options]
  );
  const handleKeyUp = useCallback(
    (e) => {
      if (buttonPressedRef.current === true) {
        setButtonPressed(false);
      }
    },
    [buttonPressedRef]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp]);

  const badgeCreditsSubmissions = useMemo(() => {
    if (selectedProfile == null) return [];
    return [...badgesMap.values()].filter((badge) => badge.credits?.includes(selectedProfile.email));
  }, [selectedProfile, badgesMap]);

  const badgeDesignSubmissions = useMemo(() => {
    if (selectedProfile == null) return [];
    return [...badgesMap.values()].filter((badge) => badge.design?.includes(selectedProfile.email));
  }, [selectedProfile, badgesMap]);

  const sortedBadgesArrayForProfile: Badge[] = useMemo(() => {
    if (selectedProfile == null) return [];
    return selectedProfile.badgesIDs
      .map((badgeID) => badgesMap.get(badgeID) ?? null)
      .sort((a, b) => {
        if (a == null || b == null) return 0;
        return (
          a.participantIDsWhoOwnThisBadge.length / a.rarityScore -
          b.participantIDsWhoOwnThisBadge.length / b.rarityScore
        );
      })
      .filter((badge) => badge != null) as Badge[];
  }, [selectedProfile, badgesMap]);

  const isConsideredMobile = window.innerWidth < 615;

  const BadgesSubmittedComponent = useMemo(() => {
    return selectedProfile ? (
      <i style={{ letterSpacing: '0.3rem' }}>
        (<i style={{ letterSpacing: '0.2rem' }}>{badgeCreditsSubmissions.length}</i>
        <i title="Badge idea">✏️</i>
        <i style={{ padding: '0 0.2rem' }}>+</i>
        <i style={{ letterSpacing: '0.2rem' }}>{badgeDesignSubmissions.length}</i>
        <i title="Design contribution">🎨</i>)
      </i>
    ) : null;
  }, [selectedProfile, badgeCreditsSubmissions.length, badgeDesignSubmissions.length]);

  const JourneysComponent = ({ journeyEmojis }: { journeyEmojis: string[] }) => {
    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '0.4rem' }}>
          <p>Journeys</p>
          {journeyEmojis?.length ? (
            <div
              style={{
                gap: '0.85rem',
                letterSpacing: '0rem',
                display: 'flex',
                flexDirection: 'row',
                margin: '0rem 0.2rem',
              }}
            >
              {journeyEmojis.map((emoji) => (
                <div
                  key={emoji}
                  style={{
                    width: '1ch',
                    height: '1ch',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {emoji}
                </div>
              ))}
            </div>
          ) : null}
          <i style={{ letterSpacing: '0.3rem' }}>
            (<i style={{ letterSpacing: '0.2rem' }}>{journeyEmojis.length}</i>
            <i style={{ padding: '0 0.2rem' }}>/</i>
            <i style={{ letterSpacing: '0.2rem' }}>{JOURNEYS.length}</i>)
          </i>
        </div>
      </div>
    );
  };

  const isDefault = selectedProfileID != null || mode === 'badgegallery' || mode === 'editingprofile';

  const getTitleByEmail = useCallback(
    (email: string) => {
      const profile = profilesMap.get(email);
      if (!profile) return '';
      const commonBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'common'
      ).length;
      const rareBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'rare'
      ).length;
      const legendaryBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'legendary'
      ).length;
      const mythicBadgesCount = profile.badgesIDs.filter(
        (badgeID) => badgesMap.get(badgeID)?.rarityName === 'mythic'
      ).length;
      const totalBadgeScore =
        rareBadgesCount * 2 + commonBadgesCount + legendaryBadgesCount * 4 + mythicBadgesCount * 8;
      const totalScoreFromJourneys = profile?.sortScore - totalBadgeScore;
      return `${
        mythicBadgesCount > 0
          ? `+ ${mythicBadgesCount * 8} pts from ${mythicBadgesCount} Mythic✦ Badge${
              mythicBadgesCount > 1 ? 's' : ''
            }\n`
          : ''
      }${
        legendaryBadgesCount > 0
          ? `+ ${legendaryBadgesCount * 4} pts from ${legendaryBadgesCount} Legendary Badge${
              legendaryBadgesCount > 1 ? 's' : ''
            }\n`
          : ''
      }${rareBadgesCount > 0 ? `+ ${rareBadgesCount * 2} pts from ${rareBadgesCount} Rare Badges\n` : ''}${
        commonBadgesCount > 0
          ? `+ ${commonBadgesCount * 1} pts from ${commonBadgesCount} Common Badge${commonBadgesCount > 1 ? 's' : ''}`
          : ''
      }${
        totalScoreFromJourneys > 0
          ? `\n+ ${totalScoreFromJourneys} pts from ${profile.journeyEmojis.length} Journey${
              profile.journeyEmojis.length > 1 ? 's' : ''
            }`
          : ''
      }`;
    },
    [badgesMap, profilesMap]
  );

  const getLastUpdatedText = useCallback((profile: Profile) => {
    const updatedRecently = new Date().getTime() - profile.lastUpdated.getTime()!;
    const AN_HOUR = 60 * 60 * 1000;
    if (updatedRecently < AN_HOUR) {
      return 'less than an hour ago';
    }
    return formatDistanceToNow(profile.lastUpdated, { addSuffix: true });
  }, []);

  const handleFormatOptionLabel = useCallback(
    (data: { value: string; label: string; topEmoji: string }) => {
      const profile = profilesMap.get(data.value);
      if (!profile) return null;

      return (
        <div
          onMouseEnter={() => setGhostProfileID(data.value)}
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '0rem',
            height: '2.5rem',
            width: '100%',
          }}
        >
          <div
            className="hide-on-selected"
            style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', gap: '0.1rem' }}
          >
            <p
              className="twenty-rem-width"
              style={{ textOverflow: 'ellipsis', maxWidth: '18rem', textWrap: 'nowrap', overflow: 'hidden' }}
            >
              {data.label}
            </p>
            <i>last updated: {getLastUpdatedText(profile)}</i>
          </div>

          <p
            title={getTitleByEmail(data.value)}
            style={{
              float: 'right',
              marginLeft: 'auto',
              alignSelf: 'center',
              whiteSpace: 'nowrap',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '0.4rem',
            }}
            className="hide-on-selected"
          >
            <i>{profilesMap.get(data.value)?.sortScore}</i>{' '}
            {data.topEmoji ? <span style={{ fontSize: '1.2rem', width: '1.1rem' }}>{data.topEmoji}</span> : null}
          </p>
        </div>
      );
    },
    [getLastUpdatedText, getTitleByEmail, profilesMap, setGhostProfileID]
  );

  const selectedProfileDetails = useCallback(
    ({ selectedProfile }: { selectedProfile: Profile | null }) => {
      return selectedProfile ? (
        <>
          {mode !== 'editingprofile' && (
            <div className="main-button disabled no-background">
              <div className="verified-line">
                <p className="talos-green">◆ verified as:</p>
                <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedProfile.email}</p>
              </div>
              <div className="verified-line">
                <p>last updated:</p>
                <span>
                  <p>{getLastUpdatedText(selectedProfile)}</p>
                  <p
                    className="edit-profile-emoji"
                    title="Edit this Profile"
                    onClick={() => {
                      setMode('editingprofile');
                      setSaveDisabled(true);
                    }}
                  >
                    🛠️
                  </p>
                </span>
              </div>
            </div>
          )}
          {mode === 'editingprofile' ? (
            <>
              <button
                className={`main-button`}
                onClick={() => {
                  setMode('collected');
                  setSaveDisabled(true);
                  setLeaveEditModeKey((prev) => prev + 1);
                }}
              >
                <p>Leave Edit Mode 🏃‍➡️</p>
              </button>
              <div className="verified-line" style={{ marginTop: '0.2rem' }}>
                <p>display name</p>
                <p />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  className="main-input"
                  value={displayName}
                  placeholder={displayName}
                  onChange={(e) => setDisplayName(e.target.value)}
                />
              </div>

              <button
                className={`main-button ${updateDisabled ? 'disabled faded-out-text' : ''}`}
                onClick={() => {
                  if (updateDisabled) return;
                  navigator.clipboard.writeText(getClipboardText(displayName, enabledBadges));
                  window.open(new_paste_url, '_blank');
                }}
                disabled={updateDisabled}
              >
                <p>
                  Update this Profile <span className="talos-green">◆</span>
                </p>
              </button>
              <div className="verified-line">
                <p className="talos-green">◆ verify as:</p>
                <p style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{selectedProfile.email}</p>
              </div>
            </>
          ) : (
            <>
              <button
                className={`main-button ${mode === 'submitted' && 'active'}
                  ${
                    badgeCreditsSubmissions.length === 0 &&
                    badgeDesignSubmissions.length === 0 &&
                    'disabled faded-out-text'
                  }
                `}
                onClick={() => (mode !== 'submitted' ? setMode('submitted') : setMode('collected'))}
              >
                <p>Badges Submitted {BadgesSubmittedComponent}</p>
              </button>
              <button
                className={`main-button ${mode === 'journeys' && 'active'}`}
                onClick={() => (mode !== 'journeys' ? setMode('journeys') : setMode('collected'))}
              >
                {JourneysComponent({ journeyEmojis: selectedProfile.journeyEmojis })}
              </button>
              {mode === 'collected' ? (
                <button className={`main-button`}>
                  <p
                    onClick={() => {
                      setMode('editingprofile');
                      setSaveDisabled(true);
                    }}
                  >
                    Edit this Profile <span className="talos-green">◆</span>
                  </p>
                </button>
              ) : (
                <div
                  className="verified-line"
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => setMode('collected')}
                >
                  <p />
                  <p>return to collected</p>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {mode !== 'editingprofile' ? (
            <>
              <button
                className={`main-button ${mode === 'badgegallery' && 'active'}`}
                onClick={() => {
                  if (mode === 'badgegallery') {
                    setMode('splash');
                  } else {
                    setMode('badgegallery');
                  }
                }}
              >
                <p>
                  Badge Gallery
                  <i style={{ letterSpacing: '0.2rem', marginLeft: '0.4rem' }}>
                    (<i style={{ letterSpacing: '0.2rem' }}>{badgesMap.size}</i>)
                  </i>
                </p>
              </button>
              {/* <button
                className={`main-button ${mode === 'people' && 'active'}`}
                onClick={() => {
                  if (mode === 'people') {
                    setMode('splash');
                  } else {
                    setMode('people');
                  }
                }}
              >
                <p>
                  People at Talos
                  <i style={{ letterSpacing: '0.2rem', marginLeft: '0.4rem' }}>
                    (<i style={{ letterSpacing: '0.2rem' }}>{badgesMap.size}</i>)
                  </i>
                </p>
              </button> */}
            </>
          ) : (
            <button
              className={`main-button`}
              onClick={() => {
                setMode('splash');
                setSaveDisabled(true);
              }}
            >
              <p>Leave Create Mode 🏃‍➡️</p>
            </button>
          )}
          {mode !== 'editingprofile' && (
            <button
              className={`main-button`}
              onClick={() => {
                setMode('editingprofile');
              }}
              style={{ marginTop: '1rem' }}
            >
              <p>
                Create / Edit Profile <span className="talos-green">◆</span>
              </p>
            </button>
          )}
          {mode === 'editingprofile' ? (
            <>
              <div className="verified-line" style={{ marginTop: '0.3rem' }}>
                <p>display name</p>
                <p />
              </div>
              <div className="input-container">
                <input
                  type="text"
                  placeholder="✏️ Display Name of Profile"
                  className="main-input"
                  value={displayName}
                  onChange={(e) => {
                    setDisplayName(e.target.value);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </div>
              {createThisProfileButton({ createDisabled, displayName, enabledBadges })}
            </>
          ) : (
            <button className="main-button" onClick={() => window.open(badge_submission_url, '_blank')}>
              <p>
                New Badge Submission <span className="talos-green">◆</span>
              </p>
            </button>
          )}
        </>
      );
    },
    [
      mode,
      getLastUpdatedText,
      displayName,
      updateDisabled,
      badgeCreditsSubmissions.length,
      badgeDesignSubmissions.length,
      BadgesSubmittedComponent,
      badgesMap.size,
      createDisabled,
      enabledBadges,
      setMode,
    ]
  );

  const effectiveBadge = sortedBadgesArrayForProfile.at(0) ?? null;
  const [loadedCount, setLoadedCount] = useState(0);
  const [displaySource, setDisplaySource] = useState<string>();

  useEffect(() => {
    setDisplaySource(effectiveBadge?.smallImageUrl);
    setLoadedCount(0);
  }, [effectiveBadge]);

  const profileBadgeIDs = useMemo(() => {
    return selectedProfile?.badgesIDs ?? [];
  }, [selectedProfile]);

  const handleOnChange = useCallback(
    (option) => {
      const profileEmail = option?.value ?? null;
      if (profileEmail == null) {
        setMode('splash');
        setSelectedProfileID(null);
        setGhostProfileID(null);
      } else if (mode === 'editingprofile' && selectedProfileID != null) {
        setMode('collected');
      } else if (mode !== 'editingprofile') {
        setMode('collected');
      }

      setSelectedProfileID(profileEmail);
    },
    [mode, selectedProfileID, setGhostProfileID, setMode, setSelectedProfileID]
  );

  return (
    <div
      className={`container ${!isDefault && (!isConsideredMobile ? 'default' : 'default-mobile')} fadeIn`}
      style={{ '--delay': '1500ms' } as React.CSSProperties}
    >
      <div className={`horizontal-flex ${isConsideredMobile && 'mobile-container'}`}>
        <div className="talos-badges-container">
          <img
            onLoad={() => {
              setImagesLoaded(true);
              if (loadedCount === 0) {
                setDisplaySource(effectiveBadge?.imageUrl);
              }
              setLoadedCount((prev) => prev + 1);
            }}
            src={displaySource ?? talosBadgesLogoURL}
            alt="Talos Logo"
            className={`talos-badges-logo glow-effect-${sortedBadgesArrayForProfile.at(0)?.rarityName}`}
            style={{ cursor: selectedProfileID ? 'pointer' : 'default' }}
            onClick={() => setSelectedBadge(effectiveBadge)}
          />
          {effectiveBadge && loadedCount < 2 && (
            <div style={{ position: 'relative' }}>
              <i className="loading-full-size-container">Loading full-size resource...</i>
            </div>
          )}
        </div>
        <div
          className={`main-buttons-container ${isConsideredMobile && 'main-buttons-container-mobile'}`}
          onMouseLeave={() => setGhostProfileID(selectedProfileID)}
        >
          <div className="h-stack" style={{ color: 'white' }}>
            <p>{selectedProfileName ? 'Talos Badges Profile' : 'Welcome to Talos Badges'}</p>
            <p
              title="Sort by"
              style={{
                cursor: 'pointer',
                userSelect: 'none',
                color: 'lightgray',
              }}
              onClick={() => {
                setSortBy(sortBy === 'score' ? 'last updated' : 'score');
              }}
            >
              ↓ {sortBy}
            </p>
          </div>
          <Select
            value={options.find((option) => option.value === selectedProfileID) ?? null}
            options={options}
            placeholder={mode === 'editingprofile' ? 'Select existing profile to edit...' : 'Select profile...'}
            isClearable
            menuIsOpen={menuIsOpen}
            onMenuOpen={() => setMenuIsOpen(true)}
            onMenuClose={() => setMenuIsOpen(false)}
            openMenuOnFocus={false}
            onChange={handleOnChange}
            formatOptionLabel={handleFormatOptionLabel}
          />

          {selectedProfileDetails({ selectedProfile })}

          {selectedProfileID == null ? (
            <div className="verified-line" style={{ position: 'absolute', width: '23rem', top: '14rem' }}>
              <p />
              <p className="talos-green">◆ talos login</p>
            </div>
          ) : null}
        </div>
      </div>
      {mode === 'collected' || mode === 'submitted' || mode === 'badgegallery' || mode === 'editingprofile' ? (
        <div className={`collected-badges-container ${isConsideredMobile && 'collected-badges-container-mobile'}`}>
          <BadgeGroupsComponent
            key={leaveEditModeKey}
            profileBadgeIDs={profileBadgeIDs}
            badgesMap={badgesMap}
            mode={mode}
            onClickBadge={setSelectedBadge}
            setSaveDisabled={setSaveDisabled}
            setEnabledBadgesForClipboard={setEnabledBadges}
            showPercentages={mode !== 'badgegallery' && mode !== 'submitted'}
            badgeCreditsSubmissions={badgeCreditsSubmissions.map((badge) => badge.id)}
            badgeDesignSubmissions={badgeDesignSubmissions.map((badge) => badge.id)}
            email={selectedProfile?.email ?? ''}
          />
        </div>
      ) : null}
      {mode === 'journeys' && selectedProfileID ? (
        <JourneysContainer
          badgesMap={badgesMap}
          profilesMap={profilesMap}
          selectedProfile={selectedProfile}
          onClickBadge={setSelectedBadge}
        />
      ) : null}
    </div>
  );
};
