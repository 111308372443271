interface BadgeGroup {
  name: string;
  colors: string[]; // [startColor, endColor] for a vibrant gradient
  badgesIDs: string[];
}

export const BADGE_GROUPS: BadgeGroup[] = [
  {
    name: 'Growth & Tenure',
    colors: ['#008000', '#00B000'],
    badgesIDs: ['eaze', 'tpbo', '86xv', 'gu8b', 'nogh', '1tgz', 'tk16', 'q6ss'],
  },
  {
    name: 'Around the Offices',
    colors: ['#008080', '#20B2AA'],
    badgesIDs: ['fk3y', 'ziyg', '56jo', 'zprr', 'qpwb', '21yr', 'hpai', '26vr'],
  },
  {
    name: 'SRT Awards',
    colors: ['#FFD700', '#FFA500'],
    badgesIDs: ['x7tl', 'snp0', 'cxaa', 'pq4t', '20nu', 'b4uv', 'rt3p', 't4lx'],
  },
  {
    name: 'Community & Events',
    colors: ['#FF007F', '#FF5A5F'],
    badgesIDs: ['gm0p', 'rw0q', 'rtdk', '52nh', 'htci', '9x0m', 'c3gi', 'g0x1'],
  },
  {
    name: 'Talos Influencers Club',
    colors: ['#FF1493', '#FF8C00'],
    badgesIDs: ['g4xq', 'edgc', 'gcr2', 'fyzv', '7ays', 'mh96', '6sj5', '0gfr'],
  },
  {
    name: 'Talent & Hiring',
    colors: ['#10ac84', '#06F7C9'],
    badgesIDs: ['n0w4', '1a0x', 'slg4', '0lkm', 'bxbq', 'mgju', 'ixey', 'zuoo'],
  },
  {
    name: 'Culinary Excellence',
    colors: ['#FF6347', '#8B4513'],
    badgesIDs: ['wg99', 'ifu1', 'ao3c', '3mjs', 't61t', 'zqtm', 'c9da'],
  },
  {
    name: 'Creativity Boosters',
    colors: ['#FF00FF', '#FF7F50'],
    badgesIDs: ['uwpa', '1ze3', '65ry', 'dr56', 'hoeg', 'y0az', 'iuvn', 'krih'],
  },
  {
    name: 'Power Planners',
    colors: ['#DC143C', '#FF1493'],
    badgesIDs: ['sy7t', '1mew', '4xjt', 'nnm0', 'om4e', 'h588', 'ao8z', '1m01'],
  },
  {
    name: 'Tech Wizards',
    colors: ['#F50057', '#FF9100'],
    badgesIDs: ['p12z', 'uv79', 'jdoi', 'ayyc', 'n26f', 'a1y2', 'olof', 'jjqu'],
  },
  {
    name: 'Brave Mishaps',
    colors: ['#282828', '#FF0000'],
    badgesIDs: ['mxv2', 'a4jq', '8nll', 'iqzd', 'r2v8', 'bpzs', 'nmyu', 'dz9q'],
  },
  {
    name: 'Gaming on the Job',
    colors: ['#4a4a4a', '#999999'],
    badgesIDs: ['lvrp', 'jw0k', 'l1ys', 'gu53', 'jt0d', 'hl4y', 'xray'],
  },
  {
    name: 'Global Adventures',
    colors: ['#007F7F', '#00FFBF'],
    badgesIDs: ['herm', 'qpnd', '20c8', '7y79', 'tnlq', 'nwqw', 'deal'],
  },
  {
    name: 'League of Champions',
    colors: ['#FF8C00', '#FF4500'],
    badgesIDs: ['99lz', 'j4l9', 'p5m0', 'ibdm', '62ex', 'b2x1', 'mqpm', '2j0v'],
  },
];
