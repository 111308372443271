interface PathStep {
  badgeOrs: string[];
  badgeAnds: string[];
}

export interface Journey {
  name: string;
  path: PathStep[];
  id: string;
  rarityName: string;
  rarityImageUrl: string;
  emoji: string;
}

export const JOURNEYS: Journey[] = [
  {
    name: `Culture Quest`,
    id: `culture-quest`,
    rarityName: 'rare',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/rare.png`,
    emoji: '🌎',
    path: [
      {
        badgeOrs: [],
        badgeAnds: ['21yr', '1tgz'],
      },
      {
        badgeOrs: ['tnlq', '9x0m'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['hpai', 'tk16'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Talos Trendsetter`,
    id: `talos-trendsetter`,
    rarityName: 'common',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/common.png`,
    emoji: '☀️',
    path: [
      {
        badgeOrs: ['7y79', 'rw0q'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['b4uv'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['x7tl', '7ays'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Host of Fun`,
    id: `host-of-fun`,
    rarityName: 'rare',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/rare.png`,
    emoji: '🥳',
    path: [
      {
        badgeOrs: ['nnm0'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['jt0d', 'hl4y'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['rt3p', 'htci'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Growth Gardener`,
    id: `growth-gardener`,
    rarityName: 'common',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/common.png`,
    emoji: '🌱',
    path: [
      {
        badgeAnds: ['tpbo'],
        badgeOrs: [],
      },
      {
        badgeAnds: [],
        badgeOrs: ['q6ss', 'ixey'],
      },
      {
        badgeAnds: [],
        badgeOrs: ['fyzv', '1ze3', 'tk16'],
      },
    ],
  },
  {
    name: `Creative Spark`,
    id: `creative-spark`,
    rarityName: 'rare',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/rare.png`,
    emoji: '🎨',
    path: [
      {
        badgeOrs: ['dr56', 'krih'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['iuvn', 'y0az', '65ry'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['3mjs', 'c9da', 'gu53'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Engineer's Dynamics`,
    id: `engineer-dynamics`,
    rarityName: 'common',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/common.png`,
    emoji: '⚒️',
    path: [
      {
        badgeOrs: ['nmyu', 'iqzd'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['hoeg', 'jjqu'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['p12z', 'jdoi'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Master of Many Skills`,
    id: `master-of-many-skills`,
    rarityName: 'legendary',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/legendary.png`,
    emoji: '️‍🔥',
    path: [
      {
        badgeOrs: [],
        badgeAnds: ['jjqu', 'ifu1', 'rtdk'],
      },
      {
        badgeOrs: [],
        badgeAnds: ['a4jq', 'ao3c', 't4lx'],
      },
      {
        badgeOrs: ['wg99', 'bpzs', 'pq4t'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Operations Juggernaut`,
    id: `operations-juggernaut`,
    rarityName: 'legendary',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/legendary.png`,
    emoji: '🥊',
    path: [
      {
        badgeOrs: ['1mew', 'p12z'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['4xjt', 'uv79'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['ayyc', 'slg4'],
        badgeAnds: [],
      },
    ],
  },
  {
    name: `Crowd Favorite`,
    id: `crowd-favorite`,
    rarityName: 'legendary',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/legendary.png`,
    emoji: '🌟',
    path: [
      {
        badgeOrs: ['hoeg', 'n26f'],
        badgeAnds: [],
      },
      {
        badgeOrs: [],
        badgeAnds: ['7ays', 'snp0', '52nh'],
      },
      {
        badgeOrs: [],
        badgeAnds: ['cxaa', 'htci', 'edgc'],
      },
    ],
  },
  {
    name: `Talos Influencer`,
    id: `talos-influencer`,
    rarityName: 'legendary',
    rarityImageUrl: `${process.env.PUBLIC_URL}/images/legendary.png`,
    emoji: '🎤',
    path: [
      {
        badgeOrs: [],
        badgeAnds: ['gcr2'],
      },
      {
        badgeOrs: ['0lkm', '6sj5'],
        badgeAnds: [],
      },
      {
        badgeOrs: ['0gfr', 'mgju', 'mh96'],
        badgeAnds: [],
      },
    ],
  },
];

export const isJourneyCompleted = (journey: Journey, badgeIDs: string[]) => {
  return journey.path.every(
    (pathStep) =>
      pathStep.badgeAnds.every((badgeID) => badgeIDs.includes(badgeID)) &&
      (pathStep.badgeOrs.length === 0 || pathStep.badgeOrs.some((badgeID) => badgeIDs.includes(badgeID)))
  );
};
